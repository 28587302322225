/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useState, useRef, useCallback } from 'react'
import Slider from 'react-slick'
import { graphql } from 'gatsby'
import './Property.scss'
import loadable from '@loadable/component'
import Seo from '../../components/Seo/Seo'
import next from '../../assets/images/next.png'
import back from '../../assets/images/back.png'
import terrain from '../../assets/images/terrain.png'
import floorArea from '../../assets/images/floor_area.png'
import landArea from '../../assets/svg/land_area.svg'
import building from '../../assets/svg/building.svg'
import floors from '../../assets/svg/floors.svg'
import projectArea from '../../assets/svg/project_area.svg'
import residentialUnits from '../../assets/svg/residential_units.svg'
import FullScreenIcon from '../../assets/images/icons/fullScreen.svg.png'
import useDraggable from '../../hooks/useDraggable'
import MinusIcon from '../../assets/images/icons/minus-icon.png'
import PlusIcon from '../../assets/images/icons/plus-icon.png'
import UnitLinkBtn from '../../components/UnitLinkBtn'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const PropertyNavigationSliced = loadable(() =>
  import('../../components/PropertyNavigationSliced/PropertyNavigationSliced'),
)

type TowerTypes = {
  overviewDetails?: {
    seoOverviewTitle: string
    seoOverviewDescription: string
    propertyStatus: string
    propertyTitle: string
    propertyDetails: string
    imagesGallery: {
      description: string
      featured: string
      url: string
    }[]
    panoramas?: {
      url: string
      featured: string
    }[]
  }
  floorPlans?: {
    noOfFloors: string
    unitFloorPlansDefault: string
    noOfResidentialUnits: string
  }
  locationLandmarks?: {
    locationBrief?: string
    region?: string
  }
}

type PropertyDataTypes = {
  id: string
  originalId: string
  unitLinkBtn: string
  name: string
  overviewDefault: string
  image?: {
    description: string
    featured: string
    url: string
  }
  slug: string
  logo: string
  projectName: string
  location?: string
  propertyPrice?: string
  propertySizeFloorArea: string
  propertySizeLotArea: string
  propertyType: string
  propertyPriceRangeHighest?: string
  propertyPriceRangeLowest?: string
  towers: TowerTypes[]
  terrain: string
  ltsAndApprovalNo?: string
}

type PropertyPropTypes = {
  data: {
    propertyData: PropertyDataTypes
  }
}

const Property = ({
  data: { propertyData },
}: PropertyPropTypes): ReactElement => {
  const sliderRef = useRef<Slider>(null)
  const mobileSliderRef = useRef<Slider>(null)
  const [fullScreen, setFullScreen] = useState(false)
  const [selected, setSelected] = useState('')
  const [mainTower] = useState<TowerTypes | null>(
    propertyData.towers ? propertyData.towers[0] || null : null,
  )
  const [displayFeaturedImages] = useState<string[]>(
    mainTower?.overviewDetails?.imagesGallery?.map((image) => image.url) || [],
  )
  const aerialView = mainTower?.overviewDetails?.panoramas
    ? mainTower?.overviewDetails?.panoramas[0]?.url
    : null

  const [zoomLvl, setZoomLvl] = useState(1)

  const DraggableCard = ({ children }: any): JSX.Element => {
    const cardRef = useRef(null)
    if (zoomLvl > 1) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDraggable(cardRef)
    }

    return (
      <div className="card" ref={cardRef}>
        {children}
      </div>
    )
  }

  const nextImageMobile = useCallback(() => {
    if (mobileSliderRef?.current?.slickNext) {
      mobileSliderRef.current.slickNext()
    }
  }, [mobileSliderRef])

  const prevImageMobile = useCallback(() => {
    if (mobileSliderRef?.current?.slickPrev) {
      mobileSliderRef.current.slickPrev()
    }
  }, [mobileSliderRef])

  const nextImage = useCallback(() => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    } else if (mobileSliderRef?.current?.slickNext) {
      mobileSliderRef.current.slickNext()
    }
  }, [sliderRef, mobileSliderRef])

  const prevImage = useCallback(() => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    } else if (mobileSliderRef?.current?.slickPrev) {
      mobileSliderRef.current.slickPrev()
    }
  }, [sliderRef, mobileSliderRef])

  const seoTitle = propertyData.towers[0]?.overviewDetails?.seoOverviewTitle
  const defaultTitle = `Affordable ${
    propertyData?.propertyType === 'Estate'
      ? 'Residential and Commercial Properties'
      : `${propertyData?.propertyType}`
  } in ${propertyData?.towers[0]?.locationLandmarks?.locationBrief} | ${
    propertyData.name
  } `

  const seoDescription =
    propertyData.towers[0]?.overviewDetails?.seoOverviewDescription
  const defaultDescription =
    propertyData?.towers[0]?.overviewDetails?.propertyDetails

  return (
    <Layout>
      <Seo
        title={seoTitle || defaultTitle}
        jsonData={{
          '@type': 'Apartment',
          description: seoDescription || defaultDescription,
        }}
        ogMetaData={{
          description: seoDescription || defaultDescription,
        }}
      />

      <div className="selected-property">
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
        />
        <div className="selected-property-info">
          <div className="selected-property-info-details">
            <h4 className="title">{propertyData.name}</h4>
            <h4 className="tagline">
              {mainTower?.overviewDetails?.propertyTitle}
            </h4>
            <h4
              className="description"
              dangerouslySetInnerHTML={{
                __html: mainTower?.overviewDetails?.propertyDetails
                  ? mainTower?.overviewDetails?.propertyDetails
                  : '',
              }}
            />
            <p
              className="selected-property-location-details-foot-note"
              dangerouslySetInnerHTML={{
                __html: propertyData.ltsAndApprovalNo
                  ? propertyData.ltsAndApprovalNo
                  : '',
              }}
            />
          </div>
          <div className="details">
            <div className="item">
              <span>
                {propertyData.propertyType === 'Estate'
                  ? 'lots'
                  : propertyData.propertyType}
              </span>
            </div>
            <div className="item">
              <span>
                P
                {Math.abs(
                  Number(
                    parseInt(
                      propertyData.propertyPriceRangeLowest?.replace(
                        /,/g,
                        '',
                      ) || '0',
                      10,
                    ),
                  ),
                ) / 1.0e6}
                M - P
                {Math.abs(
                  Number(
                    parseInt(
                      propertyData.propertyPriceRangeHighest?.replace(
                        /,/g,
                        '',
                      ) || '0',
                      10,
                    ),
                  ),
                ) / 1.0e6}
                M{' '}
              </span>
            </div>
            <div className="item">
              Lowest Monthly Amortization P
              {propertyData?.propertyPrice
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
          </div>
        </div>
        {fullScreen && (
          <div className="amenities-fullscreen">
            <img src={selected} alt="amenities" />
            <img
              src={FullScreenIcon}
              onClick={(): void => {
                setSelected('')
                setFullScreen(false)
              }}
              id="fullscreen"
              alt="property item"
            />
          </div>
        )}
        <div className="selected-property-image-slider">
          <Slider
            swipeToSlide={false}
            arrows={false}
            dots={false}
            fade
            easing="ease"
            className="banner-slider"
            ref={sliderRef}
            afterChange={(): void => setZoomLvl(1)}
          >
            {displayFeaturedImages.map((image) => (
              <div className="slider-image" key={image}>
                <img
                  src={FullScreenIcon}
                  onClick={(): void => {
                    setSelected(image)
                    setFullScreen(true)
                  }}
                  id="fullscreen"
                  alt="property item"
                />
                <DraggableCard>
                  <img
                    key={image}
                    src={image}
                    alt="property item"
                    style={{ transform: `scale(${zoomLvl})` }}
                  />
                  <div style={{ transform: `scale(${zoomLvl})` }} />
                </DraggableCard>
                <div
                  className="zoom-btns"
                  style={{ padding: '2px', background: '#Fff' }}
                >
                  <img
                    src={PlusIcon}
                    style={{ borderBottom: '1px solid #c5c5c6' }}
                    onClick={(): void =>
                      setZoomLvl((prev) => {
                        if (prev * 1.25 > 3) {
                          return prev
                        }
                        return prev * 1.25
                      })
                    }
                    alt="+"
                  />
                  <img
                    src={MinusIcon}
                    alt="-"
                    onClick={(): void =>
                      setZoomLvl((prev) => {
                        if (prev / 1.25 < 1) {
                          return 1
                        }
                        return prev / 1.25
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </Slider>

          <div className="nav-button-container">
            <button
              type="button"
              className="nav"
              onClick={(): void => prevImage()}
            >
              <img src={back} alt="backIcon" />
            </button>
            <button
              type="button"
              className="nav"
              onClick={(): void => nextImage()}
            >
              <img src={next} alt="nextIcon" />
            </button>
          </div>
        </div>
        {propertyData.propertyType !== 'Condominium' ? (
          <div className="selected-property-details">
            <span>Project Details:</span>
            <div className="content">
              <div className="info">
                <div className="img-container">
                  <img src={landArea} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>Land Area</h3>
                  <h4>{propertyData.propertySizeLotArea} hectares</h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={terrain} alt="terrainIcon" />
                </div>
                <div className="info-container">
                  <h3>Terrain</h3>
                  <h4>{propertyData.terrain}</h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={landArea} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>Units</h3>
                  <h4>
                    {propertyData.towers
                      ? `${propertyData.towers[0]?.floorPlans?.unitFloorPlansDefault} ${propertyData.propertyType} units`
                      : ''}
                  </h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={floorArea} alt="floorAreaIcon" />
                </div>
                <div className="info-container">
                  <h3>Floor Area</h3>
                  <h4>{propertyData.propertySizeFloorArea} sqm</h4>
                </div>
              </div>
            </div>
            <UnitLinkBtn unitLinkBtn={propertyData.unitLinkBtn} />
          </div>
        ) : (
          <div className="selected-property-details">
            <span>Project Details:</span>
            <div className="content">
              <div className="info">
                <div className="img-container">
                  <img src={projectArea} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>Project Area</h3>
                  <h4>{propertyData.propertySizeLotArea} sqm</h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={building} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>No. of Buildings</h3>
                  <h4>
                    {propertyData.towers
                      ? propertyData.towers[0]?.floorPlans
                          ?.unitFloorPlansDefault
                      : ''}
                  </h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={floors} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>No. of Floors:</h3>
                  <h4>
                    {propertyData.towers
                      ? propertyData.towers[0]?.floorPlans?.noOfFloors
                      : ''}
                  </h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={residentialUnits} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>No. of Residential Units:</h3>
                  <h4>
                    {propertyData.towers
                      ? propertyData.towers[0]?.floorPlans?.noOfResidentialUnits
                      : ''}
                  </h4>
                </div>
              </div>
            </div>
            <UnitLinkBtn unitLinkBtn={propertyData.unitLinkBtn} />
          </div>
        )}
      </div>
      {/* mobile view starts here */}
      <div className="selected-property-mobile">
        <div className="selected-property-image-slider">
          <Slider
            swipeToSlide={false}
            arrows={false}
            dots={false}
            fade
            easing="ease"
            className="banner-slider"
            ref={mobileSliderRef}
          >
            {displayFeaturedImages.map((image) => (
              <img
                key={image}
                className="slider-image"
                src={image}
                alt="slider-item"
              />
            ))}
          </Slider>

          <div className="nav-button-container">
            <button
              type="button"
              className="nav"
              onClick={(): void => prevImageMobile()}
            >
              <img src={back} alt="backIcon" />
            </button>
            <button
              type="button"
              className="nav"
              onClick={(): void => nextImageMobile()}
            >
              <img src={next} alt="nextIcon" />
            </button>
          </div>
        </div>
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
        />
        <div className="selected-property-info">
          <h4 className="title">{propertyData.name}</h4>
          <h4 className="tagline">
            {mainTower?.overviewDetails?.propertyTitle}
          </h4>
          <h4
            className="description"
            dangerouslySetInnerHTML={{
              __html: mainTower?.overviewDetails?.propertyDetails
                ? mainTower?.overviewDetails?.propertyDetails
                : '',
            }}
          />
          <div className="details">
            <div className="item">
              <span>{propertyData.propertyType}</span>
            </div>
            <div className="item">
              <span>
                P
                {Math.abs(
                  Number(
                    parseInt(
                      propertyData.propertyPriceRangeLowest?.replace(
                        /,/g,
                        '',
                      ) || '0',
                      10,
                    ),
                  ),
                ) / 1.0e6}
                M - P
                {Math.abs(
                  Number(
                    parseInt(
                      propertyData.propertyPriceRangeHighest?.replace(
                        /,/g,
                        '',
                      ) || '0',
                      10,
                    ),
                  ),
                ) / 1.0e6}
                M{' '}
              </span>
            </div>
            <div className="item">
              Lowest Monthly Amortization P
              {propertyData?.propertyPrice
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
          </div>
        </div>
        {propertyData.propertyType !== 'Condominium' ? (
          <div className="selected-property-details">
            <span>Project Details:</span>
            <div className="content">
              <div className="info">
                <div className="img-container">
                  <img src={landArea} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>Land Area</h3>
                  <h4>{propertyData.propertySizeLotArea} hectares</h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={terrain} alt="terrainIcon" />
                </div>
                <div className="info-container">
                  <h3>Terrain</h3>
                  <h4>{propertyData.terrain}</h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={landArea} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>Units</h3>
                  <h4>
                    {propertyData.towers
                      ? `${propertyData.towers[0]?.floorPlans?.unitFloorPlansDefault} units`
                      : ''}
                  </h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={floorArea} alt="floorAreaIcon" />
                </div>
                <div className="info-container">
                  <h3>Floor Area</h3>
                  <h4>{propertyData.propertySizeFloorArea} sqm</h4>
                </div>
              </div>
              <UnitLinkBtn unitLinkBtn={propertyData.unitLinkBtn} />
            </div>
          </div>
        ) : (
          <div className="selected-property-details">
            <span>Project Details:</span>
            <div className="content">
              <div className="info">
                <div className="img-container">
                  <img src={building} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>No. of Buildings</h3>
                  <h4>
                    {propertyData.towers
                      ? propertyData.towers[0]?.floorPlans
                          ?.unitFloorPlansDefault
                      : ''}
                  </h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={residentialUnits} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>No. of Residential Units:</h3>
                  <h4>
                    {propertyData.towers
                      ? propertyData.towers[0]?.floorPlans?.noOfResidentialUnits
                      : ''}
                  </h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={floors} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>No. of Floors:</h3>
                  <h4>
                    {propertyData.towers
                      ? propertyData.towers[0]?.floorPlans?.noOfFloors
                      : ''}
                  </h4>
                </div>
              </div>
              <div className="info">
                <div className="img-container">
                  <img src={projectArea} alt="planIcon" />
                </div>
                <div className="info-container">
                  <h3>Project Area</h3>
                  <h4>{propertyData.propertySizeLotArea} sqm</h4>
                </div>
              </div>
            </div>
            <UnitLinkBtn unitLinkBtn={propertyData.unitLinkBtn} />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Property

export const pageQuery = graphql`
  query GhostPostQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
  }
`
