import React from 'react'
import './style.scss'

function UnitLinkBtn({ unitLinkBtn }: any) {
  return (
    <>
      {unitLinkBtn ? (
        <div className="unit-link-btn">
          <div>
            <span>Interested in this Property?</span>
            <a href={unitLinkBtn} target="_blank">
              Click Here To See The Available Units
            </a>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default UnitLinkBtn
